import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, IconArticle } from 'components/Ui/styled';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { editIconTestId, deleteIconTestId } from 'constants';

const CalenderAction = (props) => {
  const { params, updateCalendar, deleteCalendar, calendarAccess } = props;
  const editClickHandler = () => {
    updateCalendar(params);
  };
  const deleteClickHandler = () => {
    deleteCalendar(params);
  };
  const handleCalendarAccess = () => {
    calendarAccess(params);
  };

  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={editClickHandler} data-testid={editIconTestId}>
        <EditIcon />
      </ButtonIcon>
      <ButtonIcon
        onClick={handleCalendarAccess}
        data-testid="calendar-access-icon"
      >
        <Tooltip title="Calendar access" arrow={false} placement="top">
          <IconArticle />
        </Tooltip>
      </ButtonIcon>
      <ButtonIcon onClick={deleteClickHandler} data-testid={deleteIconTestId}>
        <DeleteIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};

export default CalenderAction;
