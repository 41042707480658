import React from 'react';
import Grid from '@mui/material/Grid';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { dropDownSameOptions } from 'utils/commonUtils';

const StudentAttendanceRecordFields = (props) => {
  const { control, defaultValue, errors, availableReport } = props;

  return (
    <>
      <Grid item xs={12}>
        <FormInputDropdown
          name="semester"
          control={control}
          options={dropDownSameOptions(
            availableReport?.params?.semester?.allowedValues
          )}
          defaultValue={defaultValue?.semester}
          errors={!!errors.semester}
          helperText={errors?.semester?.message}
          label="Semester"
        />
      </Grid>
      <Grid item xs={12}>
        <FormInputText
          name="studentIds"
          type="text"
          label="Student Id's"
          control={control}
          errors={!!errors.studentIds}
          helperText={errors?.studentIds?.message}
        />
      </Grid>
    </>
  );
};

export default StudentAttendanceRecordFields;
