import { API } from 'aws-amplify';
import { TRACKERS_URL } from 'constants';

export const getTrackers = async () => {
  const trackers = await API.get('delta-api', `${TRACKERS_URL}`);
  return trackers;
};

export const getTracker = async (trackerId) => {
  const tracker = await API.get('delta-api', `${TRACKERS_URL}/${trackerId}`);
  return tracker;
};

export const postTracker = async (payload) => {
  const myPayload = {
    body: payload,
  };
  await API.post('delta-api', `${TRACKERS_URL}`, myPayload);
};

export const postRenameTracker = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.post('delta-api', `${TRACKERS_URL}/${trackerId}`, myPayload);
};

export const putReorderTrackerColumns = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.put('delta-api', `${TRACKERS_URL}/${trackerId}/columns`, myPayload);
};

export const postColumn = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/columns`,
    myPayload
  );
};

export const getTrackerColumns = async (trackerId, gradeValue) => {
  const trackerColumns = await API.get(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/grades/${gradeValue}/columns`
  );
  return trackerColumns;
};

export const getTrackerGradeLevel = async (
  trackerId,
  gradeValue,
  studentType,
  instructor,
  course,
  search
) => {
  const queryStringParameters = {};
  if (studentType) {
    queryStringParameters.studentType = studentType;
  }
  if (instructor) {
    queryStringParameters.instructor = instructor;
  }
  if (course) {
    queryStringParameters.course = course;
  }
  if (search) {
    queryStringParameters.search = search;
  }
  const trackerGradeLevel = await API.get(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/grades/${gradeValue}`,
    {
      headers: {},
      queryStringParameters: queryStringParameters,
    }
  );
  return trackerGradeLevel;
};

export const putStudentTrackerCell = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.put('delta-api', `${TRACKERS_URL}/${trackerId}`, myPayload);
};

export const putHideUnHideColumn = async (payload, trackerId, gradeValue) => {
  const myPayload = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/grades/${gradeValue}/columns`,
    myPayload
  );
};

export const postAddStatus = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/addStatus`,
    myPayload
  );
};

export const postRemoveStatus = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/removeStatus`,
    myPayload
  );
};

export const postColumnRename = async (payload, trackerId) => {
  const myPayload = {
    body: payload,
  };
  await API.post(
    'delta-api',
    `${TRACKERS_URL}/${trackerId}/columns/rename`,
    myPayload
  );
};
