import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import {
  getDataGridHeading,
  pageSizeChangeHandler,
  useNotification,
} from 'utils/commonUtils';
import { intialPageSize } from 'constants';
import {
  getPublicProfileListData,
  publicProfileColumns,
} from './PublicProfileDataGrid';
import {
  getPublicProfiles,
  getPublicProfile,
} from '../services/publicProfileAPI';
import PublicProfileDialog from './PublicProfileDialog';

const PublicProfileComponent = () => {
  const { handleErrorResponse, NotificationPopup } = useNotification();

  const [publicProfileDialog, setPublicProfileDialog] = useState(false);
  const [publicProfileForm, setPublicProfileForm] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [publicProfile, setPublicProfile] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchPublicProfile();
  }, []);

  const fetchPublicProfile = async () => {
    setPublicProfile({ ...publicProfile, isLoading: true });
    try {
      const publicProfileData = await getPublicProfiles();
      setPublicProfile({ ...publicProfile, isLoading: false });
      setPublicProfile((prev) => ({
        ...prev,
        rows: getPublicProfileListData(publicProfileData),
      }));
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
      setPublicProfile({ ...publicProfile, isLoading: false });
    }
  };

  const handleViewPublicProfile = async (params) => {
    try {
      setIsFormLoading(true);
      const { row } = params;
      const publicProfile = await getPublicProfile(row.username);
      setPublicProfileForm(publicProfile?.profile)
      setIsFormLoading(false);
      setPublicProfileDialog(true);
    } catch (error) {
      setIsFormLoading(false);
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const pageSizeChangeTracking = pageSizeChangeHandler(setPublicProfile);

  return (
    <>
      <MDBox>
        <Card> 
          {getDataGridHeading('Public Profile')}
          <DeltaDataGrid
            listData={publicProfile}
            columns={publicProfileColumns(handleViewPublicProfile)}
            setListData={pageSizeChangeTracking}
          />
        </Card>
      </MDBox>
      <PublicProfileDialog
        publicProfileDialog={publicProfileDialog}
        setPublicProfileDialog={setPublicProfileDialog}
        isFormLoading={isFormLoading}
        publicProfileForm={publicProfileForm}
      />
      <NotificationPopup />
    </>
  );
};

export default PublicProfileComponent;
