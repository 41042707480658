import { formatDate } from 'utils/commonUtils';
import { renderTrackingAction } from './TrackingAction';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';

export const trackingColumns = (
  handleEditTracking,
  handleStatusOption,
) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: (params) =>
      renderTrackingAction(
        params,
        handleEditTracking,
        handleStatusOption,
      ),
  },
  {
    headerName: 'Name',
    field: 'name',
    renderCell: renderCellExpand,
    width: 300,
  },
  {
    headerName: 'Description',
    field: 'columns',
    renderCell: renderCellExpand,
    flex: 1,
  },
  {
    headerName: 'Last Modified',
    field: 'updatedAt',
    flex: 1,
  },
];

export const getTrackingListData = (trackingData) => {
  const items = trackingData?.trackers;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      trackerId,
      name,
      columns,
      statusOptions,
      hiddenColumnsByGrade,
      version,
      updatedAt,
    }) => ({
      id: trackerId,
      name,
      statusOptions,
      hiddenColumnsByGrade,
      version,
      updatedAt: formatDate(updatedAt),
      columns: columns.join(', '),
      arrayOfColumns: columns,
      noOfColumns: columns.length,
    })
  );
};
