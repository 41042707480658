import React from 'react';
import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';

const MyCards = () => {
  const navigate = useNavigate();

  const myProfile = useSelector((state) => state?.myProfile);

  const { tokenGroups, metadata } = myProfile || {};
  const { watchingSchools, watchingStudents } = metadata || {};

  const myTotalGroups = tokenGroups?.length;
  const myTotalSchools = watchingSchools?.length;
  const myTotalStudents = watchingStudents?.length;

  const handleMyGroups = () => {
    navigate(`/my-profile/groups`);
  };
  const handleMySchools = () => {
    navigate(`/my-profile/watching-schools`);
  };
  const handleMyStudents = () => {
    navigate(`/my-profile/watching-students`);
  };

  return (
    <>
      <MDBox pt={3}></MDBox>
      <Grid container spacing={2}>
        <Grid item sm={12} md={4}>
          <Box onClick={handleMyGroups}>
            <MiniStatisticsCard
              title={{ text: 'My Groups' }}
              count={myTotalGroups}
              icon={{ color: 'dark', component: <GroupsRoundedIcon /> }}
              direction='left'
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={4}>
          <Box onClick={handleMySchools}>
            <MiniStatisticsCard
              title={{ text: 'Watching Schools' }}
              count={myTotalSchools}
              icon={{ color: 'dark', component: <SchoolRoundedIcon /> }}
              direction='left'
            />
          </Box>
        </Grid>
        <Grid item sm={12} md={4}>
          <Box onClick={handleMyStudents}>
            <MiniStatisticsCard
              title={{ text: 'Watching Students' }}
              count={myTotalStudents}
              icon={{ color: 'dark', component: <PersonRoundedIcon /> }}
              direction='left'
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default MyCards;
