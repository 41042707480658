import { API } from 'aws-amplify';

export const getGroupsList = async () => {
  const groupList = await API.get('delta-api', 'user-mgmt/groups', {});
  const { groups } = groupList;
  return groups || [];
};

export const putGroup = async (payload, groupValue) => {
  const myInit = {
    body: payload,
  };
  await API.put('delta-api', `user-mgmt/groups/${groupValue}`, myInit);
};

export const postGroup = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `user-mgmt/groups`, myInit);
};

export const postGroupMembers = async (groupName, username) => {
  await API.post(
    'delta-api',
    `user-mgmt/groups/${groupName}/members/${username}`,
    {}
  );
};

export const deleteGroupMembers = async (groupName, username) => {
  await API.del(
    'delta-api',
    `user-mgmt/groups/${groupName}/members/${username}`,
    {}
  );
};

export const postGroupReadWriteSupervisors = async (groupName, username) => {
  await API.post(
    'delta-api',
    `user-mgmt/groups/${groupName}/readWriteSupervisors/${username}`,
    {}
  );
};

export const deleteGroupReadWriteSupervisors = async (groupName, username) => {
  await API.del(
    'delta-api',
    `user-mgmt/groups/${groupName}/readWriteSupervisors/${username}`,
    {}
  );
};

export const postGroupReadOnlySupervisors = async (groupName, username) => {
  await API.post(
    'delta-api',
    `user-mgmt/groups/${groupName}/readOnlySupervisors/${username}`,
    {}
  );
};

export const deleteGroupReadOnlySupervisors = async (groupName, username) => {
  await API.del(
    'delta-api',
    `user-mgmt/groups/${groupName}/readOnlySupervisors/${username}`,
    {}
  );
};

export const deleteGroup = async (groupName) => {
  await API.del('delta-api', `user-mgmt/groups/${groupName}`, {});
};
