import React from 'react';
import Grid from '@mui/material/Grid';
import { DropdownInputList } from 'utils/commonUtils';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { FormInputSwitch } from 'components/Common/Forms/FormInputSwitch';
import { studentTypeOptions } from 'constants';
import { dropDownSameOptions } from 'utils/commonUtils';

const ActiveStudentsReportFields = (props) => {
  const {
    control,
    register,
    defaultValue,
    handleStudentTypesChange,
    availableReport,
  } = props;

  return (
    <>
      <Grid item xs={12}>
        <FormInputDropdown
          name="studentTypes"
          control={control}
          multiple={true}
          checked={defaultValue.studentTypes}
          renderValue={(selected) => (
            <DropdownInputList selectedValues={selected} />
          )}
          options={dropDownSameOptions(
            availableReport?.params?.studentTypes?.allowedValues
          )}
          label="Student Types"
          register={register('studentTypes', {
            onChange: (e) => handleStudentTypesChange(e),
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputSwitch
          control={control}
          name="includeSchedule"
          label="Include Schedule"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormInputSwitch
          control={control}
          name="includeDashboardPassword"
          label="Include Dashboard Password"
        />
      </Grid>
    </>
  );
};

export default ActiveStudentsReportFields;
