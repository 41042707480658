import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { MultiFormGrid, IconButtonMB } from 'components/Ui/styled';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

const MultiEditFormTextField = (props) => {
  const {
    field,
    index,
    label,
    control,
    errors,
    isEditable,
    onEditClick,
    register
  } = props;

  const names = `fields[${index}].value`;
  const error = !!errors?.fields?.[index]?.value;
  const helperText = errors?.fields?.[index]?.value?.message;
  const defaultValue = field.value;

  return (
    <MultiFormGrid
      item
      xs={12}
      key={field.id}
    >
      <Controller
        name={names}
        control={control}
        register={register}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            {...field}
            label={label}
            style={{ width: '100%' }}
            variant={isEditable ? 'outlined' : 'standard'}
            error={error}
            helperText={helperText}
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        )}
      />
      <IconButtonMB onClick={onEditClick} data-testid="edit-icon">
        <EditRoundedIcon />
      </IconButtonMB>
    </MultiFormGrid>
  );
};

export default MultiEditFormTextField;
