import { rowsPerPage } from 'constants';
import { renderTaskAction } from './TasksDataGridAction';
import { getRowClassName, getRowHeight, formatDate } from 'utils/commonUtils';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import {
  OutlinedIconCheckCircle,
  OutlinedIconCancel,
  ButtonSpinnerProgress,
} from 'components/Ui/styled';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const getTasksDataGrid = (
  taskList,
  taskColumns,
  handelPageChange,
  handleOnPageSizeChange
) => {
  const { rows, page, total, pageSize, isLoading } = taskList;

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={taskColumns}
      page={page - 1}
      rowCount={total}
      rowsPerPageOptions={rowsPerPage}
      pagination
      paginationMode='server'
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      onPageChange={(newPage) => handelPageChange(newPage)}
      loading={isLoading}
      hideFooterPagination={isLoading}
      onPageSizeChange={(newPageSize) => handleOnPageSizeChange(newPageSize)}
    />
  );
};

export const taskColumns = (
  updateTask,
  handleTaskCompleted,
  taskCompletedLoading,
  loadingRowId
) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: (params) => renderTaskAction(params, updateTask),
  },
  {
    headerName: 'Mark Complete',
    field: 'completed',
    width: 100,
    align: 'center',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      const isLoading = params.row.id === loadingRowId && taskCompletedLoading;
      return (
        <Button variant="text">
          <Checkbox
            disabled={isLoading}
            checked={params.row.completed}
            onClick={() => handleTaskCompleted(params)}
            icon={<OutlinedIconCancel />}
            checkedIcon={<OutlinedIconCheckCircle />}
          />
          {isLoading && <ButtonSpinnerProgress size={24} />}
        </Button>
      );
    },
  },
  {
    headerName: 'Title',
    field: 'title',
    renderCell: renderCellExpand,
    width: 250,
  },
  {
    headerName: 'Description',
    field: 'description',
    renderCell: renderCellExpand,
    width: 350,
  },
  { headerName: 'User', field: 'user', width: 250 },
  { headerName: 'Due On', field: 'dueOn', type: 'date', width: 150 },
  { headerName: 'Created At', field: 'createdAt', type: 'date', width: 150 },
  { headerName: 'Created By', field: 'createdBy', type: 'date', width: 250 },
  { headerName: 'Updated At', field: 'updatedAt', type: 'date', width: 150 },
  { headerName: 'Task Type', field: 'taskType', width: 150 },
  {
    headerName: 'Completed At',
    field: 'completedAt',
    type: 'date',
    width: 150,
  },
  {
    headerName: 'Note',
    field: 'note',
    renderCell: renderCellExpand,
    width: 200,
  },
];

export const getTaskListData = (taskListData) => {
  const items = taskListData?.items;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      id,
      title,
      description,
      user,
      completed,
      dueOn,
      createdAt,
      createdBy,
      updatedAt,
      taskType,
      completedAt,
      note,
    }) => ({
      id,
      title,
      description,
      user,
      completed,
      dueOn: formatDate(dueOn),
      createdAt: formatDate(createdAt),
      createdBy,
      updatedAt: formatDate(updatedAt),
      taskType,
      completedAt: formatDate(completedAt),
      note,
    })
  );
};
