import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import { DialogBox, TypographyUser } from 'components/Ui/styled';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  postGroupMembers,
  postGroupReadWriteSupervisors,
  postGroupReadOnlySupervisors,
  deleteGroupMembers,
  deleteGroupReadWriteSupervisors,
  deleteGroupReadOnlySupervisors,
} from '../services/userGroupApi';
import { displaySnackbar } from 'components/snackbar/store/SnackbarSlice';
import {
  userCountPaperProps,
  groupMembersSaved,
  groupMembersDeleted,
} from 'constants';
import {
  CardCheckbox,
  CardDivider,
  LeftShiftButton,
  RightShiftButton,
  UserCountList,
} from './groupStyled';
import MDBox from 'components/MDBox';
import {
  getSnackBar,
  useNotification,
  showBackdropLoader,
} from 'utils/commonUtils';
import {
  postUserLocation,
  deleteUserLocation,
} from 'layouts/users/services/userApis';
import {
  postAttendanceAuditUserAccess,
  deleteAttendanceAuditUserAccess,
} from 'layouts/auditManagement/services/attendanceAuditsApi';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const MembersDialog = (props) => {
  const dispatch = useDispatch();
  const {
    setMembersDialog,
    membersDialog,
    fetchGroups,
    canManageGroups,
    canManageGroupMembership,
    loading,
    listName,
  } = props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [permission, setPermission] = useState(false);
  const [isAPICall, setIsAPICall] = useState(false);

  const availableUsersList = useSelector((state) => state?.availableUsersList);

  const groupUsers = useSelector((state) => state?.groupUsers);
  const formName = useSelector((state) => state?.groupUsers?.formName);
  const {
    groupName,
    username,
    members,
    groupMemberList,
    groupReadWriteSupervisorList,
    groupReadOnlySupervisorList,
    assignedLocations,
    assignedUsers,
    accessUsersList,
  } = groupUsers;

  const memberList = availableUsersList?.filter((item) => {
    if (formName === 'Members') {
      return !groupMemberList?.includes(item.username);
    }
    if (formName === 'Read/Write Supervisors') {
      return !groupReadWriteSupervisorList?.includes(item.username);
    }
    if (formName === 'Read Only Supervisors') {
      return !groupReadOnlySupervisorList?.includes(item.username);
    }
    if (formName === 'Assigned Locations') {
      const getLocation = assignedLocations?.find(
        (items) => items.id === item.id
      );
      return !getLocation?.val;
    }
    if (formName === 'Assigned Users') {
      return !assignedUsers?.includes(item.username);
    }
    if (formName === 'Attendance Audit Access') {
      return !accessUsersList?.includes(item.username);
    }
  });
  
  const groupMembers = availableUsersList?.filter((item) => {
    if (formName === 'Members') {
      return groupMemberList?.includes(item.username);
    }
    if (formName === 'Read/Write Supervisors') {
      return groupReadWriteSupervisorList?.includes(item.username);
    }
    if (formName === 'Read Only Supervisors') {
      return groupReadOnlySupervisorList?.includes(item.username);
    }
    if (formName === 'Assigned Locations') {
      const getLocation = assignedLocations?.find(
        (items) => items.id === item.id
      );
      return getLocation?.val;
    }
    if (formName === 'Assigned Users') {
      return assignedUsers?.includes(item.username);
    }
    if (formName === 'Attendance Audit Access') {
      return accessUsersList?.includes(item.username);
    }
  });

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const defaultValue = {
    groupUsers: groupUsers,
    allMembers: members,
    groupName: groupName,
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
  });
  const { reset } = useFunction;

  useEffect(() => {
    reset(defaultValue);
    setLeft(groupMembers);
    setRight(memberList);
  }, [
    defaultValue.groupUsers,
    defaultValue.allMembers,
    defaultValue.groupName,
  ]);

  useEffect(() => {
    if (formName === 'Members') {
      setPermission(false);
      if (canManageGroupMembership) {
        setPermission(true);
      }
    } else if (formName !== 'Members') {
      setPermission(false);
      if (canManageGroups) {
        setPermission(true);
      } else {
        setPermission(true);
      }
    }
  }, [formName]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedLeft = async () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    const snackBarSaved = getSnackBar(groupMembersSaved);
    try {
      setIsAPICall(true);
      for (let i = 0; i < rightChecked.length; i++) {
        const item = rightChecked[i];
        if (formName === 'Members') {
          await postGroupMembers(groupName, item.username);
        }
        if (formName === 'Read/Write Supervisors') {
          await postGroupReadWriteSupervisors(groupName, item.username);
        }
        if (formName === 'Read Only Supervisors') {
          await postGroupReadOnlySupervisors(groupName, item.username);
        }
        if (formName === 'Assigned Locations') {
          await postUserLocation(item.id, username);
        }
        if (formName === 'Assigned Users') {
          await postUserLocation(groupUsers?.id, item.username);
        }
        if (formName === 'Attendance Audit Access') {
          await postAttendanceAuditUserAccess(groupUsers?.id, item.username);
        }
      }
      setIsAPICall(false);
      fetchGroups();
      dispatch(displaySnackbar(snackBarSaved));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleCheckedRight = async () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    try {
      setIsAPICall(true);
      const snackBarDeleted = getSnackBar(groupMembersDeleted);
      for (let i = 0; i < leftChecked.length; i++) {
        const item = leftChecked[i];
        if (formName === 'Members') {
          await deleteGroupMembers(groupName, item.username);
        }
        if (formName === 'Read/Write Supervisors') {
          await deleteGroupReadWriteSupervisors(groupName, item.username);
        }
        if (formName === 'Read Only Supervisors') {
          await deleteGroupReadOnlySupervisors(groupName, item.username);
        }
        if (formName === 'Assigned Locations') {
          await deleteUserLocation(item.id, username);
        }
        if (formName === 'Assigned Users') {
          await deleteUserLocation(groupUsers?.id, item.username);
        }
        if (formName === 'Attendance Audit Access') {
          await deleteAttendanceAuditUserAccess(groupUsers?.id, item.username);
        }
      }
      setIsAPICall(false);
      fetchGroups();
      dispatch(displaySnackbar(snackBarDeleted));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleClose = () => {
    setMembersDialog(false);
    setChecked([]);
    reset(defaultValue);
  };

  const membersList = (title, items) => (
    <Card>
      <TypographyUser style={{ justifyContent: 'center' }}>
        {title}
      </TypographyUser>
      <CardDivider />
      <UserCountList dense component="div" role="list">
        {items?.map((value, index) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          const displayValue =
            value.username || value.location || 'Unknown Property';
          return (
            <ListItem
              key={index}
              role="listitem"
              onClick={permission ? handleToggle(value) : undefined}
            >
              <ListItemIcon>
                <CardCheckbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${displayValue}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </UserCountList>
    </Card>
  );

  const listHeading = listName ? listName : 'Users';
  return (
    <>
      {showBackdropLoader(loading)}
      <Dialog open={membersDialog} PaperProps={userCountPaperProps}>
        <DialogBox>
          <TypographyUser variant="h5">{formName}</TypographyUser>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogBox>
        {showBackdropLoader(isAPICall)}
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{membersList(formName, left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <RightShiftButton
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </RightShiftButton>
              <LeftShiftButton
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </LeftShiftButton>
            </Grid>
          </Grid>
          <Grid item>{membersList(`Available ${listHeading}`, right)}</Grid>
        </Grid>
        <MDBox pt={5}></MDBox>
      </Dialog>
      <NotificationPopup />
    </>
  );
};

export default MembersDialog;
