import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import {
  getDataGridHeading,
  useResetSnackbar,
  useMembersState,
  pageSizeChangeHandler,
  useNotification,
} from 'utils/commonUtils';
import { intialPageSize } from 'constants';
import { getUsersList } from 'layouts/users/services/userApis';
import { useDispatch } from 'react-redux';
import { TypographyLink } from 'components/Ui/styled';
import MembersDialog from 'layouts/groups/list/MembersDialog';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import {
  setIsUserLocation,
  resetUserLocation,
} from '../store/userLocationSlice';

const UserLocationList = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { NotificationPopup, handleErrorResponse } = useNotification();

  const { loading, membersDialog, handleMembers, setMembersDialog, listName } =
    useMembersState();
  const [userLocationList, setUserLocationList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchUsersList();
  }, []);

  useEffect(() => {
    dispatch(setIsUserLocation(true));
    return () => {
      dispatch(resetUserLocation());
    };
  }, []);

  const fetchUsersList = async () => {
    try {
      setUserLocationList({ ...userLocationList, isLoading: true });
      const userList = await getUsersList();
      setUserLocationList({ ...userLocationList, isLoading: false });
      setUserLocationList((previous) => ({
        ...previous,
        rows: userList?.map((item, index) => ({
          ...item,
          id: index,
          username: item.username,
          location: item.assignedLocations.length,
          assignedLocations: item.assignedLocations,
          locationIdList: item.assignedLocations?.map((item2, index2) => ({
            id: item2.id,
            locationId: item2.val,
          })),
        })),
      }));
    } catch (error) {
      setUserLocationList({ ...userLocationList, isLoading: false });
      handleErrorResponse(
        error?.response?.data?.message ||
          'An error occurred while fetching user list'
      );
    }
  };

  const handleAssignedLocations = handleMembers('Assigned Locations');

  const columns = [
    { headerName: 'User Name', field: 'username', flex: 1 },
    {
      headerName: 'Locations',
      field: 'location',
      renderCell: (params) => {
        return (
          <TypographyLink
            variant="h6"
            onClick={() => handleAssignedLocations(params)}
          >
            {params.row.location}
          </TypographyLink>
        );
      },
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setUserLocationList);

  return (
    <>
      <Card>
        {getDataGridHeading('Assign locations to users')}
        <DeltaDataGrid
          listData={userLocationList}
          columns={columns}
          setListData={pageSizeChange}
        />
      </Card>
      <MembersDialog
        setMembersDialog={setMembersDialog}
        membersDialog={membersDialog}
        fetchGroups={fetchUsersList}
        loading={loading}
        listName={listName}
      />
      <NotificationPopup />
    </>
  );
};

export default UserLocationList;
