import { API } from 'aws-amplify';

// export const getCalendarsForAdmin = async () => {
//   const calender = await API.get('delta-api', 'calendar/adminListCalendars');
//   return calender;
// };

export const updateCalendar = async (params, calendarId) => {
  const myInit = {
    body: params,
  };
  const calender = await API.put(
    'delta-api',
    `calendar/calendars/${calendarId}`,
    myInit
  );
  return calender;
};

export const postCalendar = async (params) => {
  const myInit = {
    body: params,
  };
  const calender = await API.post('delta-api', `calendar/calendars/`, myInit);
  return calender;
};

export const deleteCalendar = async (params) => {
  const calender = await API.del(
    'delta-api',
    `calendar/calendars/${params.calendarId}`
  );
  return calender;
};

export const putCalenderPermission = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `calendar/calendars/${payload.permission.calendarId}/permission`,
    myInit
  );
};
