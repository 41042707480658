import { API } from 'aws-amplify';
import { REPORT_TOOL_URL } from 'constants';

export const getAvailableReports = async () => {
  const availableReports = await API.get(
    'delta-api',
    `${REPORT_TOOL_URL}availableReports`
  );
  return availableReports;
};

export const getAvailableReport = async (reportId) => {
  const availableReport = await API.get(
    'delta-api',
    `${REPORT_TOOL_URL}availableReports/${reportId}`
  );
  return availableReport;
};

export const getReportExecutions = async (pageSize, paginationKey, report) => {
  const reportExecutions = await API.get(
    'delta-api',
    `${REPORT_TOOL_URL}reportExecutions?`,
    {
      headers: {},
      queryStringParameters: {
        limit: pageSize,
        paginationKey: paginationKey,
        ...(report ? { report } : {}),
      },
    }
  );
  return reportExecutions;
};

export const getOutputReportExecution = async (executionId) => {
  const availableReports = await API.get(
    'delta-api',
    `${REPORT_TOOL_URL}reportExecutions/${executionId}/output`,
    { responseType: 'blob' }
  );

  return availableReports;
};

export const getReportExecution = async (executionId) => {
  const availableReports = await API.get(
    'delta-api',
    `${REPORT_TOOL_URL}reportExecutions/${executionId}`
  );
  return availableReports;
};

export const postReportExecution = async (payload) => {
  const myPayload = {
    body: payload,
  };
  await API.post('delta-api', `${REPORT_TOOL_URL}reportExecutions`, myPayload);
};
