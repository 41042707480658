/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// @fullcalendar components
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// @mui material components
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Custom styles for Calendar
import CalendarRoot from 'examples/Calendar/CalendarRoot';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';
import AddEventDialog from 'layouts/applications/calendar/components/CalendarEvents/AddEvent';
import {
  getCalendarEvent,
  newCalendarEvent,
} from 'layouts/applications/calendar/components/CalendarEvents/store/calenderEventSlice';
import { getCalenderEvent } from 'layouts/applications/calendar/services/calendarEventsApi';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import Grid from '@mui/material/Grid';
import { setSelectCalendar } from 'layouts/applications/calendar/store/CalenderSlice';
import { useForm } from 'react-hook-form';
import { useCalendarPermissionCustomHook } from 'layouts/applications/calenderAdmin/utils/calendarUtils';
import PermissionDialog from 'layouts/applications/calendar/PermisionDialog';
import { format } from 'date-fns';
import { dateFnsFormat, yearMonthFormat } from 'constants';
import { getCalenderEvents } from 'layouts/applications/calendar/services/calendarEventsApi';
import { getCategory } from 'layouts/applications/calendar/components/services/categoryApi';
import { getYearMonthData } from './store/yearMonthSlice';
import { setCategories } from 'layouts/applications/calendar/components/Categories/store/calenderCategorySlice';
import { getCalendarDetails } from './store/calendarDetailsSlice';
import { getCalendarsDetails } from './services/calendarsApi';
import Categories from 'layouts/applications/calendar/components/Categories';
import { CalendarAccessErrorGrid } from 'components/Ui/styled';
import { timezoneOffset } from 'utils/commonUtils';
import { getPublicProfiles } from 'layouts/publicProfile/services/publicProfileAPI';

function Calendar(props) {
  const {
    calendarEvents,
    setCalendarEvents,
    calendarDropDownList,
    currentCalenderId,
    fetchCalendarListData,
    isBackDropLoading,
    setIsBackDropLoading,
    header,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const addEvent = useCalendarPermissionCustomHook('addEvent');
  const updateEvent = useCalendarPermissionCustomHook('updateEvent');
  const managePermission = useCalendarPermissionCustomHook('managePermission');

  const [openEventDialog, setOpenEventDialog] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);
  const [callEvents, setCallEvents] = useState(false);
  const [usersReferencedata, setUsersReferencedata] = useState([]);
  const [isCalendarAccessError, setIsCalendarAccessError] = useState(false);
  const [calendarAccessError, setCalendarAccessError] = useState(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const calendarOptionList = calendarDropDownList?.calendars?.map(
    (item, index) => ({
      id: item.calendarId,
      value: item.name,
    })
  );

  const yearMonth = useSelector((state) => state.yearMonth);

  const currentYearMonth = new Date(yearMonth);
  const currentYear = `${currentYearMonth.getFullYear()}`;
  const currentMonth = `${currentYearMonth.getMonth() + 1}`;

  useEffect(() => {
    if (calendarDropDownList?.calendars?.length >= 1) {
      if (currentCalenderId) {
        fetchCalendarDataOfParticularMonth(currentYear, currentMonth);
      }
    }
  }, [calendarDropDownList, currentCalenderId]);

  const defaultValue = {
    selectCalendar: currentCalenderId ? currentCalenderId : '',
  };

  const useFunction = useForm({
    defaultValues: defaultValue,
    //resolver: yupResolver(input_schema),
  });
  const { control, register, reset } = useFunction;

  const handleDateSelect = () => {
    const newEmptyCalendarEvent = {
      newEvent: true,
      title: null,
      description: null,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      location: null,
      isAllDayEvent: false,
      categoryId: '',
      id: '',
    };
    dispatch(newCalendarEvent(newEmptyCalendarEvent));
    setOpenEventDialog(true);
  };

  const handleEventClick = async (clickInfo) => {
    const { event } = await getCalenderEvent(
      currentCalenderId,
      clickInfo.event._def.extendedProps.eventId
    );
    dispatch(getCalendarEvent(event));
    setOpenEventDialog(true);
  };

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.selectCalendar]);

  const setPermmission = () => {
    setOpenPermission(true);
  };

  const showBackDropLoader = () => (
    <Backdrop
      sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isBackDropLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const handleEvents = async (arg) => {
    const currentdate = format(new Date(), yearMonthFormat);
    const currentEventDate = format(
      new Date(arg.view.currentStart),
      yearMonthFormat
    );
    const current = new Date(arg.view.currentStart);
    const getCurrentMonth = `${current.getMonth() + 1}`;
    const getCurrentYear = `${current.getFullYear()}`;
    if (currentdate === currentEventDate) {
      if (callEvents) {
        fetchCalendarDataOfParticularMonth(getCurrentYear, getCurrentMonth);
      }
      dispatch(
        getYearMonthData(format(new Date(arg.view.currentStart), dateFnsFormat))
      );
    } else {
      fetchCalendarDataOfParticularMonth(getCurrentYear, getCurrentMonth);
      dispatch(
        getYearMonthData(format(new Date(arg.view.currentStart), dateFnsFormat))
      );
      setCallEvents(true);
    }
  };

  const fetchCalendarDataOfParticularMonth = async (
    getCurrentYear,
    getCurrentMonth
  ) => {
    try {
      setIsBackDropLoading(true);
      const json = await getCalenderEvents(
        currentCalenderId,
        getCurrentYear,
        getCurrentMonth
      );
      const calendarDetails = await getCalendarsDetails(currentCalenderId);
      const getCategoryList = await getCategory(currentCalenderId);
      const categoryData = getCategoryList?.categories;
      const getCategoryColor = (categoryId) => {
        const getColor = categoryData?.find(
          (item) => item.categoryId === categoryId
        );
        return getColor?.color;
      };
      const { events } = json;
      const eventsData = events?.map((item, index) => ({
        ...item,
        id: item.eventId,
        title: item.title,
        description: item.description,
        start: item.startDate,
        end: moment(new Date(item.endDate))
          .add(timezoneOffset, 'day')
          .startOf('day')
          .toDate(),
        startTime: item.startDate,
        endTime: item.endDate,
        allDay: item.isAllDayEvent,
        location: item.location,
        categoryId: item.categoryId,
        color: getCategoryColor(item.categoryId),
      }));
      setCalendarEvents((prveState) => ({
        data: eventsData,
      }));
      dispatch(setCategories(categoryData));
      dispatch(getCalendarDetails(calendarDetails));

      const usersData = await getPublicProfiles();

      setUsersReferencedata(usersData?.profiles);
      setIsCalendarAccessError(false);
      setCalendarAccessError(null);
      setIsBackDropLoading(false);
    } catch (error) {
      setIsBackDropLoading(true);
      setIsCalendarAccessError(true);
      setCalendarAccessError(error.response.data.message);
      setIsBackDropLoading(false);
    }
  };

  return (
    <>
      {isBackDropLoading && showBackDropLoader()}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormInputDropdown
            name="selectCalendar"
            type="text"
            control={control}
            options={calendarOptionList}
            label="Select Calendar"
            register={register('selectCalendar', {
              onChange: (e) => dispatch(setSelectCalendar(e.target.value)),
            })}
          />
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
        <Grid item xs={12} sm={3}>
          {managePermission && (
            <Button variant="text" onClick={() => setPermmission()}>
              set permission
            </Button>
          )}
        </Grid>
      </Grid>
      {isCalendarAccessError ? (
        <CalendarAccessErrorGrid container spacing={2}>
          <MDTypography variant="body2">{calendarAccessError}</MDTypography>
        </CalendarAccessErrorGrid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9.5} sx={{ height: 'max-content' }}>
            <Card sx={{ height: '100%' }}>
              <MDBox
                pt={header.title || header.date ? 2 : 0}
                px={2}
                lineHeight={1}
              >
                {header.title ? (
                  <MDTypography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {header.title}
                  </MDTypography>
                ) : null}
                {header.date ? (
                  <MDTypography
                    component="p"
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    {header.date}
                  </MDTypography>
                ) : null}
              </MDBox>
              <CalendarRoot p={2} ownerState={{ darkMode }}>
                <FullCalendar
                  {...rest}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  events={calendarEvents}
                  displayEventTime={false}
                  datesSet={(arg) => {
                    handleEvents(arg);
                  }}
                  editable={updateEvent}
                  selectable={addEvent}
                  select={addEvent ? handleDateSelect : undefined}
                  eventClick={updateEvent ? handleEventClick : undefined}
                  initialView="dayGridMonth"
                  height="100%"
                />
              </CalendarRoot>
            </Card>
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <MDBox mb={3}>
              <Categories
                fetchCalendarDataOfParticularMonth={
                  fetchCalendarDataOfParticularMonth
                }
                calendarDropDownList={calendarDropDownList}
              />
            </MDBox>
          </Grid>
        </Grid>
      )}
      <AddEventDialog
        open={openEventDialog}
        setOpen={setOpenEventDialog}
        fetchCalendarDataOfParticularMonth={fetchCalendarDataOfParticularMonth}
      />
      <PermissionDialog
        open={openPermission}
        setOpen={setOpenPermission}
        usersReferencedata={usersReferencedata}
        fetchCalendarDataOfParticularMonth={fetchCalendarDataOfParticularMonth}
        fetchCalendarListData={fetchCalendarListData}
        calendarDropDownList={calendarDropDownList}
      />
    </>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: '',
    date: '',
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
