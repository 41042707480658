import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import { scrollbarStyles } from 'components/Ui/styled';

export const ExpandContainer = styled(Box)`
  align-items: center;
  line-height: 24px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
`;

export const CellText = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
`;

export const ExpandText = styled(Typography)`
  padding: 8px;
  font-weight: 400;
  color: #000000;
  max-height: 200px;
  ${scrollbarStyles}
`;

export const PopperStyle = styled(Popper)`
  @media screen and (max-width: 1200px) {
    padding: 0px 60px;
    max-width: ${(props) => `${props.theme.palette.spacing[1] * 64}px`};
  }

  padding: 0px 60px 0px 330px;
  max-width: ${(props) => `${props.theme.palette.spacing[1] * 96}px`};
  z-index: 9999;
  min-width: auto;
`;

export const ContainerBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
`;
