import { createSlice } from '@reduxjs/toolkit';
import {
  _setReportToolExecutionPagination,
  _setReportToolPaginationPreviousKey,
} from './reportToolExecutionAction';

const initialState = {
  reportToolExecutionPagination: [],
  reportToolExecutionPaginationPreviousKey: '',
};

export const reportToolExecutionPaginationSlice = createSlice({
  name: 'delta/reportToolExecutionPagination',
  initialState,
  reducers: {
    setReportToolExecutionPagination: _setReportToolExecutionPagination,
    setReportToolPaginationPreviousKey: _setReportToolPaginationPreviousKey,
    resetReportToolExecutionPagination: () => initialState,
  },
});

export const {
  setReportToolExecutionPagination,
  setReportToolPaginationPreviousKey,
  resetReportToolExecutionPagination,
} = reportToolExecutionPaginationSlice.actions;

export default reportToolExecutionPaginationSlice.reducer;
