import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Card from '@mui/material/Card';
import {
  intialPageSize,
  canManageStudentTrackersPermissionMessage,
} from 'constants';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import { getTrackingListData, trackingColumns } from './TrackingDataGrid';
import TrackingDialog from './TrackingDialog';
import {
  pageSizeChangeHandler,
  getDataGridHeading,
  useNotification,
  permissionCheck,
} from 'utils/commonUtils';
import { getTracker, getTrackers } from '../../services/studentTrackerApi';
import StatusOptionsDialog from './StatusOptionsDialog';
import { useDispatch } from 'react-redux';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';
import { setTrackingForm } from 'layouts/studentTracking/store/trackingSlice/trackingSlice';
import { setIsNewTracking } from 'layouts/studentTracking/store/trackingSlice/trackingSlice';
import TrackerEditDialog from './TrackerEditDialog';

const TrackingComponent = () => {
  const dispatch = useDispatch();
  const {
    setOpenNotification,
    setNotificationMessage,
    handleErrorResponse,
    NotificationPopup,
  } = useNotification();

  const [trackerId, setTrackerId] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [statusOptionsDialog, setStatusOptionsDialog] = useState(false);
  const [trackingDialog, setTrackingDialog] = useState(false);
  const [trackingEditDialog, setTrackingEditDialog] = useState(false);
  const [trackers, setTrackers] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  const canManageStudentTrackers = usePermissionCustomHook(
    'CAN_MANAGE_STUDENT_TRACKERS'
  );

  useEffect(() => {
    fetchTrackers();
  }, []);

  const fetchTrackers = async () => {
    setTrackers({ ...trackers, isLoading: true });
    try {
      const trackingData = await getTrackers();
      setTrackers({ ...trackers, isLoading: false });
      setTrackers((prev) => ({
        ...prev,
        rows: getTrackingListData(trackingData),
      }));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
      setTrackers({ ...trackers, isLoading: false });
    }
  };

  const pageSizeChangeTracking = pageSizeChangeHandler(setTrackers);

  const handleNewTracking = () => {
    permissionCheck(
      canManageStudentTrackers,
      () => {
        setTrackingDialog(true);
        dispatch(setIsNewTracking(true));
      },
      handleErrorResponse,
      canManageStudentTrackersPermissionMessage
    );
  };

  const handleStatusOption = (params) => {
    const { row } = params;
    setTrackerId(row.id);
    setStatusOptionsDialog(true);
  };

  const handleEditTracking = (params) => {
    permissionCheck(
      canManageStudentTrackers,
      async (params) => {
        try {
          setIsFormLoading(true);
          const { row } = params;
          const tracker = await getTracker(row.id);
          setTrackerId(row.id);
          dispatch(setTrackingForm(tracker));
          setTrackingEditDialog(true);
          setIsFormLoading(false);
        } catch (error) {
          setIsFormLoading(false);
          setOpenNotification(true);
          setNotificationMessage(error?.response?.data?.message);
        }
      },
      handleErrorResponse,
      canManageStudentTrackersPermissionMessage,
      params
    );
  };

  return (
    <>
      <MDBox>
        <Card>
          {getDataGridHeading('Trackers', 'New Tracker', handleNewTracking)}
          <DeltaDataGrid
            listData={trackers}
            columns={trackingColumns(handleEditTracking, handleStatusOption)}
            setListData={pageSizeChangeTracking}
          />
        </Card>
      </MDBox>
      <TrackingDialog
        open={trackingDialog}
        setOpen={setTrackingDialog}
        fetchTrackers={fetchTrackers}
      />
      <TrackerEditDialog
        open={trackingEditDialog}
        setOpen={setTrackingEditDialog}
        fetchTrackers={fetchTrackers}
        trackerId={trackerId}
        isFormLoading={isFormLoading}
        setTrackerId={setTrackerId}
      />
      <StatusOptionsDialog
        trackerId={trackerId}
        statusOptionsDialog={statusOptionsDialog}
        setStatusOptionsDialog={setStatusOptionsDialog}
        fetchTrackers={fetchTrackers}
      />
      <NotificationPopup />
    </>
  );
};

export default TrackingComponent;
