import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel, { a11yProps } from 'components/Common/TabPanel/TabPanel';
import GeneralInfoTab from './GeneralInfoTab';
import EmploymentTab from './EmploymentTab';
import DocumentsTab from './documentsTab/DocumentsTab';
import GroupsTab from './GroupsTab';
import NotesTab from './NotesTab';
import Grid from '@mui/material/Grid';
import { TabsContainerBox } from 'components/Ui/styled';

const StaffProfileTabs = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabsContainerBox container data-testid="StaffProfileTabs">
      <Grid item xs={12}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="staffProfileTabs"
        >
          <Tab label="General Info" {...a11yProps(0)} />
          <Tab label="Employment" {...a11yProps(1)} />
          <Tab label="Documents" {...a11yProps(2)} />
          <Tab label="Groups" {...a11yProps(3)} />
          <Tab label="Admin" {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <GeneralInfoTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EmploymentTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentsTab />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <GroupsTab />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <NotesTab />
        </TabPanel>
      </Grid>
    </TabsContainerBox>
  );
};

export default StaffProfileTabs;
