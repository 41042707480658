import { createSlice } from '@reduxjs/toolkit';
import { _setReportExecution } from './reportExecutionAction';

export const ReportExecutionSlice = createSlice({
  name: 'delta/reportExecutionForm',
  initialState: null,
  reducers: {
    setReportExecution: _setReportExecution,
    resetReportExecution: () => null,
  },
});

export const { setReportExecution, resetReportExecution } =
  ReportExecutionSlice.actions;

export default ReportExecutionSlice.reducer;
