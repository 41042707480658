import Tooltip from '@mui/material/Tooltip';
import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon, RoundedIconCloudDownload } from 'components/Ui/styled';
import { fileTypes } from './reportToolComponentConstants';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

export const downloadReportExecutionFile = async (
  params,
  getOutputReportExecution,
  handleErrorResponse,
  setIsFormLoading
) => {
  const { row } = params;

  try {
    setIsFormLoading(true);
    const scores = await getOutputReportExecution(row.id);
    const downloadFileType = scores.type;

    const extension = fileTypes[downloadFileType] || 'bin';

    const blob = new Blob([scores], { type: downloadFileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `report_scores.${extension}`);
    document.body.appendChild(link);
    link.click();
    setIsFormLoading(false);
  } catch (error) {
    const blob = error?.response?.data;
    const errorMessage = await blob?.text();
    const errorJson = JSON.parse(errorMessage);
    handleErrorResponse(errorJson.message);
    setIsFormLoading(false);
  }
};

export const renderReportToolExecutionAction = (
  params,
  handleDownloadReportExecution,
  handleViewReportExecution
) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon
        onClick={() => handleViewReportExecution(params)}
        data-testid="viewButtonIcon"
      >
        <VisibilityRoundedIcon />
      </ButtonIcon>
      <ButtonIcon
        onClick={() => handleDownloadReportExecution(params)}
        data-testid="buttonIcon"
      >
        <Tooltip title="Download" arrow={false} placement="left">
          <RoundedIconCloudDownload />
        </Tooltip>
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
