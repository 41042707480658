import { formatDate } from 'fullcalendar';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { renderReportToolExecutionAction } from './utils/reportToolComponentUtils';

export const reportExecutionColumns = (
  handleDownloadReportExecution,
  handleViewReportExecution,
  reportExecutionList
) => {
  const fieldsWithData = reportExecutionList?.rows?.reduce((fields, row) => {
    Object.keys(row).forEach((key) => {
      if (row[key] !== undefined) {
        fields?.add(key);
      }
    });
    return fields;
  }, new Set());

  fieldsWithData?.add('action');

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) =>
        renderReportToolExecutionAction(
          params,
          handleDownloadReportExecution,
          handleViewReportExecution
        ),
    },
    { headerName: 'User Name', field: 'username', width: 250 },
    {
      headerName: 'Report',
      field: 'report',
      renderCell: renderCellExpand,
      width: 250,
    },
    {
      headerName: 'Description',
      field: 'description',
      renderCell: renderCellExpand,
      width: 350,
    },
   { headerName: 'Output Type', field: 'outputType', width: 150 },
    {
      headerName: 'Semester',
      field: 'semester',
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Student Ids',
      field: 'studentIds',
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Include Dashboard Password',
      field: 'includeDashboardPassword',
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Include Schedule',
      field: 'includeSchedule',
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: 'Student Types',
      field: 'studentTypes',
      width: 150,
      renderCell: renderCellExpand,
    },
    { headerName: 'Status', field: 'status', width: 150 },
    {
      headerName: 'Status Msg',
      field: 'statusMsg',
      width: 150,
      renderCell: renderCellExpand,
    },
    { headerName: 'Created At', field: 'createdAt', type: 'date', width: 150 },
    { headerName: 'Started At', field: 'startedAt', type: 'date', width: 250 },
    { headerName: 'Finished At', field: 'finishedAt', type: 'date', width: 150 },
  ];

  return columns?.filter((column) => fieldsWithData?.has(column?.field));
};

export const getReportExecutionListData = (reportExecutionListData) => {
  const items = reportExecutionListData?.items;
  if (!items || items?.length === 0) {
    return [];
  }
  return items?.map(
    ({
      id,
      username,
      description,
      report,
      status,
      statusMsg,
      createdAt,
      startedAt,
      finishedAt,
      outputType,
      reportParams,
      metadata,
    }) => ({
      id,
      username,
      description,
      report,
      status,
      statusMsg,
      createdAt: formatDate(createdAt),
      startedAt: formatDate(startedAt),
      finishedAt: formatDate(finishedAt),
      outputType,
      semester: reportParams?.semester,
      studentIds: reportParams?.studentIds,
      includeDashboardPassword:
        reportParams?.includeDashboardPassword && 'True',
      includeSchedule: reportParams?.includeSchedule && 'True',
      studentTypes: Array.isArray(reportParams?.studentTypes)
        ? reportParams?.studentTypes.join(', ')
        : reportParams?.studentTypes,
      metadata,
    })
  );
};
