import { rowsPerPage } from 'constants';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getRowClassName, getRowHeight } from 'utils/commonUtils';
import { renderReportAction } from './ReportDataGridAction';
import { renderCellExpand } from 'components/Common/GridCell/GridCellExpand';
import { TypographyLink } from 'components/Ui/styled';

export const getReportsDataGrid = (reports, columns, setReports) => {
  const { rows, pageSize, isLoading } = reports || {};

  return (
    <DataGridPro
      autoHeight
      getRowHeight={getRowHeight}
      rows={rows}
      columns={columns}
      pagination
      rowsPerPageOptions={rowsPerPage}
      pageSize={pageSize}
      getRowClassName={(params) => getRowClassName(params)}
      loading={isLoading}
      onPageSizeChange={(newPageSize) =>
        setReports((prveState) => ({
          ...prveState,
          pageSize: newPageSize,
        }))
      }
    />
  );
};

export const getReportsColumns = (
  scores,
  viewReport,
  handleStudentRedirect
) => {
  if (!scores?.data || scores?.data?.length === 0) {
    return [];
  }

  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params) => renderReportAction(params, viewReport),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      valueGetter: (params) => {
        const {
          'Student First Name': studentFirstName,
          StudentFirstName: studentFirstNameAlt,
          'Student Last Name': studentLastName,
          StudentLastName: studentLastNameAlt,
          'Student Name': studentName,
        } = params.row;

        const firstName = studentFirstName || studentFirstNameAlt;
        const lastName = studentLastName || studentLastNameAlt;
        const name =
          firstName && lastName ? `${firstName} ${lastName}` : studentName;

        return `${name}`;
      },
      renderCell: (params) => {
        const fullName = params.value;
        return (
          <TypographyLink
            variant="h6"
            onClick={() => handleStudentRedirect(params)}
          >
            {fullName}
          </TypographyLink>
        );
      },
    },
  ];

  const excludeColumns = [
    'Student First Name',
    'Student Last Name',
    'StudentFirstName',
    'StudentLastName',
    'Student Name',
  ];

  const allKeys = scores?.data?.reduce((keys, row) => {
    return keys.concat(Object.keys(row));
  }, []);

  const filterColumns = [...new Set(allKeys)].filter(
    (key) => !excludeColumns.includes(key)
  );

  const columns = filterColumns.map((key) => ({
    headerName: key,
    field: key,
    renderCell: renderCellExpand,
    disableColumnMenu: true,
    width: key === 'action' ? 80 : 120,
  }));

  return [...actionColumn, ...columns];
};

export const getReportsRow = (scores) => {
  if (!scores?.data || scores?.data?.length === 0) {
    return [];
  }

  return scores?.data?.map((row, index) => ({
    id: index,
    ...row,
  }));
};
