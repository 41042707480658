import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Card from '@mui/material/Card';
import { deleteFundingType, getFundingType } from './services/FundingTypeApi';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { renderActionItems } from 'components/Common/GridAction';
import FundingTypeDialog from './FundingTypeDialog';
import {
  resetFundingTypeForm,
  setFundingTypeForm,
  setNewFundingType,
} from './store/fundingTypeSlice';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';
import { intialPageSize } from 'constants';
import {
  getDataGridHeading,
  useResetSnackbar,
  pageSizeChangeHandler,
  useNotification,
} from 'utils/commonUtils';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const FundingType = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { NotificationPopup, handleErrorResponse } = useNotification();
  const FundingType = useSelector((state) => state?.fundingType);

  const { id } = FundingType;

  const [openFundingtypeDialog, setOpenFundingtypeDialog] = useState(false);
  const [openInventoryDeleteDialog, setOpenInventoryDeleteDialog] =
    useState(false);

  const [fundingType, setFundingType] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchFundingTypeData();
  }, []);

  const fetchFundingTypeData = async () => {
    try {
      setFundingType({ ...fundingType, isLoading: true });
      const fundingTypeList = await getFundingType();
      setFundingType({ ...fundingType, isLoading: false });

      setFundingType((previous) => ({
        ...previous,
        rows: fundingTypeList?.items.map((item, index) => ({
          ...item,
          id: item.id,
          funding: item.funding,
        })),
      }));
    } catch (error) {
      setFundingType({ ...fundingType, isLoading: false });
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const editClickHandler = (params) => {
    const { row } = params;
    dispatch(setFundingTypeForm(row));
    setOpenFundingtypeDialog(true);
  };

  const deleteClickHandler = (params) => {
    const { row } = params;
    dispatch(setFundingTypeForm(row));
    setOpenInventoryDeleteDialog(true);
  };

  const handleNewFundingType = () => {
    dispatch(resetFundingTypeForm());
    dispatch(setNewFundingType(true));
    setOpenFundingtypeDialog(true);
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      disableColumnMenu: true,
      width: 80,
      align: 'center',
      // this renderActionItems returns action items EditIcon and DeleteIcon
      renderCell: (params) =>
        renderActionItems(params, editClickHandler, deleteClickHandler),
    },
    { headerName: 'Type', field: 'funding', flex: 1 },
  ];

  const pageSizeChange = pageSizeChangeHandler(setFundingType);

  return (
    <DashboardLayout>
      <CustomizedSnackbars />
      <DashboardNavbar />

      <MDBox>
        <Card>
          {getDataGridHeading(
            'Funding Type',
            'New Funding Type',
            handleNewFundingType
          )}
          <DeltaDataGrid
            listData={fundingType}
            columns={columns}
            setListData={pageSizeChange}
          />
        </Card>
      </MDBox>
      <FundingTypeDialog
        fetchFundingTypeData={fetchFundingTypeData}
        open={openFundingtypeDialog}
        setOpen={setOpenFundingtypeDialog}
      />
      <CommonDeleteDialog
        openDeleteDialog={openInventoryDeleteDialog}
        setOpenDeleteDialog={setOpenInventoryDeleteDialog}
        title="Delete Funding Type"
        contentText="Are you sure you want to delete this funding type ?"
        fetchData={fetchFundingTypeData}
        snakBarName="funding type"
        commonDeleteApi={deleteFundingType}
        commonDeleteId={id}
      />
      <NotificationPopup />
    </DashboardLayout>
  );
};

export default FundingType;
