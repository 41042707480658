export const _setReportToolExecutionPagination = (state, action) => {
  state.reportToolExecutionPagination = [
    ...state.reportToolExecutionPagination,
    action.payload,
  ];
};

export const _setReportToolPaginationPreviousKey = (state, action) => {
  state.reportToolPaginationPreviousKey = action.payload;
};
