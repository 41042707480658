import React, { useState, useEffect, useMemo } from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';
import MDBox from 'components/MDBox';
import Sidenav from 'examples/Sidenav';
import Configurator from 'examples/Configurator';
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import routes from 'routes';
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from 'context';
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';
import { getUserDetails } from 'redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnouncementsForNotifications } from 'layouts/applications/manageAnnouncements/services/announcementApi';
import {
  setNotificationBadge,
  getAnnouncementNotifications,
} from 'layouts/dashboards/analytics/store/announcementNotification/announcementNotificationsSlice';
import { getMyPermissions } from 'layouts/users/userPermissions/services/userPermissionsApi';
import { getMyPermissionsData } from 'layouts/users/userPermissions/store/myPermissionsSlice';
import { availableUsers } from 'layouts/groups/store/availableUsersSlice';
import { getLocationData } from 'layouts/inventory/location/services/LocationApi';
import { getPublicProfiles } from 'layouts/publicProfile/services/publicProfileAPI';

//import { listNotes } from './graphql/queries';
//import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from './graphql/mutations';

//const initialFormState = { name: '', description: '' }

const Delta = (props) => {
  const { signOut, user } = props;
  const dispatchUse = useDispatch();
  //const navigate = useNavigate();
  //const [seconds, setSeconds] = useState(60);
  // const userGroups =
  //   user.signInUserSession.accessToken.payload['cognito:groups'];

  const isLogout = useSelector((state) => state.logout.isLogout);
  const userLocation = useSelector(
    (state) => state.userLocation?.isUserLocation
  );
  //const isLogoutTiming = useSelector((state) => state.logout.isLogoutTiming);

  //return (
  //  <div className="App">
  //    <h2>Welcome {user.attributes.email}</h2>
  //    <Button onClick={signOut}>Sign Out</Button>
  //  </div>
  //);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // let inactivityTimer;

  // const resetInactivityTimer = () => {
  //   clearTimeout(inactivityTimer);
  //   inactivityTimer = setTimeout(() => {
  //     dispatchUse(setIsLogoutTiming(true));
  //   }, 9 * 60 * 1000);
  // };

  // const handleUserActivity = () => {
  //   resetInactivityTimer();
  // };

  // useEffect(() => {
  //   resetInactivityTimer();
  //   window.addEventListener('mousemove', handleUserActivity);
  //   window.addEventListener('keydown', handleUserActivity);

  //   return () => {
  //     clearTimeout(inactivityTimer);
  //     window.removeEventListener('mousemove', handleUserActivity);
  //     window.removeEventListener('keydown', handleUserActivity);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isLogoutTiming) {
  //     const interval = setInterval(() => {
  //       setSeconds((prevSeconds) => prevSeconds - 1);
  //     }, 1000);
  //     if (seconds === 0) {
  //       dispatchUse(setIsLogoutTiming(false));
  //       dispatchUse(setIsLogout(true));
  //       dispatchUse(resetSelectSemester());
  //       navigate('/');
  //       signOut();
  //       clearInterval(interval);
  //       setTimeout(() => {
  //         dispatchUse(resetIsLogout());
  //         dispatchUse(resetMyPermissionsData());
  //       }, 2000);
  //     }
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }
  // }, [isLogoutTiming, seconds]);

  useEffect(() => {
    dispatchUse(getUserDetails(user.signInUserSession.accessToken.payload));
    if (!isLogout) {
      fetchMyPermissions();
      fetchAnnouncementNotifications();
      if (!userLocation) {
        fetchAvailableUsersList();
      }
    }
  }, []);

  const fetchMyPermissions = async () => {
    const myPermissions = await getMyPermissions();
    dispatchUse(getMyPermissionsData(myPermissions));
  };

  const fetchAnnouncementNotifications = async () => {
    const notificationData = await getAnnouncementsForNotifications();
    dispatchUse(getAnnouncementNotifications(notificationData));
    dispatchUse(setNotificationBadge(notificationData?.items?.length));
  };

  const fetchAvailableUsersList = async () => {
    const listOfAvailableUser = await getPublicProfiles();
    dispatchUse(availableUsers(listOfAvailableUser?.profiles));
  };

  useEffect(() => {
    if (userLocation) {
      getAvailableLocations();
    }
  }, [userLocation]);

  const getAvailableLocations = async () => {
    const listOfLocationsAvailable = await getLocationData();
    dispatchUse(availableUsers(listOfLocationsAvailable?.items));
  };

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === 'rtl' ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === 'dashboard' && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Material Dashboard PRO"
              routes={routes}
              user={user}
              signOut={signOut}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* <TimeOutPopup
              seconds={seconds}
              setSeconds={setSeconds}
              signOut={signOut}
            /> */}
            {/* configsButton */}
          </>
        )}
        {layout === 'vr' && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Delta Academy"
            routes={routes}
            user={user}
            signOut={signOut}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* <TimeOutPopup
            seconds={seconds}
            setSeconds={setSeconds}
            signOut={signOut}
          /> */}
          {/* configsButton */}
        </>
      )}
      {layout === 'vr' && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
};

export default Delta;