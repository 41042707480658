import { API } from 'aws-amplify';

export const postCalender = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.post('delta-api', `calendar/calendars`, myInit);
};

export const getSingleCalender = async (payload) => {
  const calender = await API.get('delta-api', `calendar/calendars/${payload}`);
  return calender;
};

export const putCalenderPermission = async (payload) => {
  const myInit = {
    body: payload,
  };
  await API.put(
    'delta-api',
    `calendar/calendars/${payload.permission.calendarId}/permission`,
    myInit
  );
};
