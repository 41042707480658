import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon } from 'components/Ui/styled';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

export const renderPublicProfileAction = (params, handleViewPublicProfile) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon onClick={() => handleViewPublicProfile(params)} data-testid="buttonIcon">
        <VisibilityRoundedIcon />
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
