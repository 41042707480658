import { API } from 'aws-amplify';
import { PUBLIC_PROFILE_URL } from 'constants';

export const getPublicProfiles = async () => {
  const publicProfiles = await API.get('delta-api', `${PUBLIC_PROFILE_URL}`);
  return publicProfiles;
};

export const getPublicProfile = async (publicProfileId) => {
  const publicProfile = await API.get(
    'delta-api',
    `${PUBLIC_PROFILE_URL}${publicProfileId}`
  );
  return publicProfile;
};
