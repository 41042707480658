import { createSlice } from '@reduxjs/toolkit';
import initialState from './userManagementInitialState';

const userManagementFormSlice = createSlice({
  name: 'delta/userManagementFormSlice',
  initialState,
  reducers: {
    updateUserManagementForm: (state, action) => action.payload,
    newUserManagementForm: (state, action) => action.payload,
    deleteUserManagementForm: (state, action) => action.payload,
    setResendInvitation: (state, action) => action.payload,
    resetUserManagement: () => null,
  },
});

export const {
  resetUserManagements,
  updateUserManagementForm,
  newUserManagementForm,
  deleteUserManagementForm,
  setResendInvitation,
} = userManagementFormSlice.actions;

export default userManagementFormSlice.reducer;
