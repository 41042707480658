import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { HeadingMuiTypography, GridWrapper } from 'components/Ui/styled';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDashboardPassword,
  resetDashboardPassword,
  syncDashboardPasswordToCanvas,
} from 'layouts/studentManagement/services/studentProfileApi';
import {
  showBackdropLoader,
  useNotification,
  onSave,
  getButtonSpinner,
  emptyFunction,
} from 'utils/commonUtils';
import PasswordToggle from 'components/Common/PasswordToggle';
import {
  resetDashboardPasswordSuccess,
  resetDashboardPasswordConformation,
  syncDashboardPasswordToCanvasConformation,
  syncDashboardPasswordToCanvasSuccess,
} from 'constants';
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog';
import { usePermissionCustomHook } from 'layouts/users/userPermissions/utils/permissionsUtils';

const DashboardPasswordTab = () => {
  const dispatch = useDispatch();
  const [dashboardPassword, setDashboardPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const canResetStudentDashboardPasswords = usePermissionCustomHook(
    'CAN_RESET_STUDENT_DASHBOARD_PASSWORDS'
  );

  const studentDetails = useSelector((state) => state.studentDetails);

  const { profile } = studentDetails || {};

  const { hasManagedCanvasAccount, resourceId } = profile || {};

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    fetchDashboardPassword();
  }, []);

  const fetchDashboardPassword = async () => {
    setIsLoading(true);
    try {
      const dashboardPassword = await getDashboardPassword(resourceId);
      setDashboardPassword(dashboardPassword?.dashboardPassword);
      setIsLoading(false);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const defaultValue = {
    dashboardPassword: dashboardPassword,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset, control } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.dashboardPassword]);

  const handleSyncDashboardPasswordToCanvas = () => {
    setConfirmationMessage(syncDashboardPasswordToCanvasConformation);
    setOpenConfirmationDialog(true);
  };

  const handleResetPassword = () => {
    setConfirmationMessage(resetDashboardPasswordConformation);
    setOpenConfirmationDialog(true);
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
    setConfirmationMessage(null);
  };

  const isResetPassword =
    confirmationMessage ===
    'Are you sure you want to reset dashboard password ?';

  const successMessage = isResetPassword
    ? resetDashboardPasswordSuccess
    : syncDashboardPasswordToCanvasSuccess;

  const apiCall = isResetPassword
    ? resetDashboardPassword
    : syncDashboardPasswordToCanvas;

  const onSubmit = async () => {
    onSave(
      successMessage,
      true,
      apiCall,
      emptyFunction,
      resourceId,
      '',
      fetchDashboardPassword,
      handleClose,
      setOpenNotification,
      setNotificationMessage,
      dispatch,
      setLoadingButton,
      loadingButton
    );
  };

  return (
    <>
      {isLoading && showBackdropLoader(isLoading)}
      <HeadingMuiTypography variant="h6" data-testid="DP_Heading">
        Dashboard Password
      </HeadingMuiTypography>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} sm={6.5}>
          <FormInputText
            name="dashboardPassword"
            type={showPassword ? 'text' : 'password'}
            control={control}
            label="Dashboard Password"
            readOnly={true}
            endAdornment={
              <PasswordToggle
                onClick={handleShowPassword}
                onMouseDown={handleMouseDownPassword}
                showPassword={showPassword}
              />
            }
          />
        </Grid>
      </GridWrapper>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} sm={4}>
          {hasManagedCanvasAccount &&
            getButtonSpinner(
              false,
              handleSyncDashboardPasswordToCanvas,
              'Sync Dashboard Password to Canvas',
              !canResetStudentDashboardPasswords
            )}
        </Grid>
        <Grid item xs={12} sm={3}>
          {getButtonSpinner(
            false,
            handleResetPassword,
            'Reset Dashboard Password',
            !canResetStudentDashboardPasswords
          )}
        </Grid>
      </GridWrapper>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        title={confirmationMessage}
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        loadingButton={loadingButton}
        isSubmit={true}
      />
      <NotificationPopup />
    </>
  );
};

export default DashboardPasswordTab;
