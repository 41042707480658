import { ActionWrapperBox } from 'components/Common/common.styled';
import { ButtonIcon } from 'components/Ui/styled';
import Tooltip from '@mui/material/Tooltip';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';

export const renderTrackingAction = (
  params,
  handleEditTracking,
  handleStatusOption
) => {
  return (
    <ActionWrapperBox>
      <ButtonIcon
        onClick={() => handleEditTracking(params)}
      >
        <Tooltip title="Edit Column" arrow={false} placement="top">
          <ViewWeekRoundedIcon />
        </Tooltip>
      </ButtonIcon>
      <ButtonIcon onClick={() => handleStatusOption(params)}>
        <Tooltip title="Replace Status" arrow={false} placement="top">
          <CompareArrowsRoundedIcon />
        </Tooltip>
      </ButtonIcon>
    </ActionWrapperBox>
  );
};
